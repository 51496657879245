.body {
  margin: 0;
  font-size: 12px;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(-90deg,#ffffff,#314ef5cb);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} 
.numero{
  text-align: right;
}
.celdaNumero60{
  text-align: right;
  width:60px
}
.opcion{
  color:black;
}
.form-control-sm{
  height:calc(1.2em + .5rem + 2px);
}
.btn-sm{
  padding:.25rem .5rem;
  font-size:.875rem;
  line-height:1.2;
  border-radius:.2rem
}
.input-group-text{
  height:calc(1.2em + .5rem + 0px);
}
ul {
  list-style-type: none;
  padding-left: 10px;
}
li:hover {
  background-color:rgba(62, 65, 247, 0.486);
  color:white;
}
.imgBtn{
  width:30px;
  text-align:center
}
.etapaArbol{
  color:navy;
  font-weight:bold;
}
.labelArbol{
  color:black;
  font-weight:bold;
}
ul.arbolReq {
  list-style-type: disc;
  padding-left: 10px;
}
ul.arbolProd {
  list-style-type: square;
  padding-left: 10px;
}
.letraChica{
   font-size:10px;
}